<template>
	<div class="city_introduction">
		<!-- 控制窗口显示的箭头按钮 -->
		<div class="arrow_btn">
			<img @click="isShowHigher = false" v-if="isShowHigher" src="@/assets/img/icon_close.png" />
			<img @click="isShowHigher = true" v-else src="@/assets/img/icon_open.png" />
		</div>
		<div
			:class="isShowHigher ? 'large_center_bg large_center_higher_bg showHigherWarp' : 'large_center_bg hideHigherWarp'">
			<!-- 标题 -->
			<div class="title_box fl_c al_c ju_c">
				土地流转资产概览
			</div>
			<div class="flex_box" v-if="!isShowHigher">
				<div class="item_box" v-for="(item,index) in landList" :key="index" @click="isShowHigher = true">
					<span>{{item.name}}</span>
					<span class="lt_icon"></span>
					<span class="rt_icon"></span>
				</div>
			</div>
			<div class="flex_box" v-else>
				<div class="item_box column_box" v-for="(item,index) in landList" :key="index" @click="isShowHigher = true">
					<span class="title">{{item.name}}</span>
					<div class="grid_list">
						<div class="grid_list_item">
							<div>{{item.name}}总面积</div>
							<div class="nums">{{item.area}}</div>
						</div>
						<div class="grid_list_item">
							<div>{{item.name}}总个数</div>
							<div class="nums">{{item.all}}</div>
						</div>
						<div class="grid_list_item">
							<div>已流转面积</div>
							<div class="nums">{{item.success_area}}</div>
						</div>
						<div class="grid_list_item">
							<div>已流转个数</div>
							<div class="nums">{{item.success}}</div>
						</div>
						<div class="grid_list_item">
							<div>未流转面积</div>
							<div class="nums">{{item.pending_area}}</div>
						</div>
						<div class="grid_list_item">
							<div>未流转个数</div>
							<div class="nums">{{item.pending}}</div>
						</div>
					</div>
					<span class="lt_icon"></span>
					<span class="rt_icon"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getLandData } from '@/utils/api/landAssets.js';
	import { mapState } from 'vuex';
	export default {
		name: "LandCirculateIntro",
		data() {
			return {
				isShowHigher: false, // 是否拉高简介部分
				landList: [
					{
						id: 2,
						name: '林地'
					},
					{
						id: 3,
						name: '耕地'
					},
					{
						id: 10,
						name: '宅基地'
					},
				]
			};
		},
		mounted() {
			this.getLandData();
		},
		computed: {
			...mapState(['region']),
		},
		methods: {
			async getLandData() {
				let res = await getLandData({
					town: this.region.item.town_code
				});
				this.landList.forEach(land => {
					if(res.data.data[land.id]) {
						Object.assign(land,res.data.data[land.id])
					}
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	.city_introduction {
		width: 100%;

		.arrow_btn {
			text-align: center;
			margin-bottom: 10px;

			img {
				width: 35px;
				height: 35px;
				pointer-events: auto;
				cursor: pointer;
			}
		}

		.large_center_bg {
			width: 100%;
			height: 220px;
			background-image: url(./../../assets/img/pop_down.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			// display: block;
			margin: 0 auto;
			overflow: hidden;
			box-sizing: border-box;
			pointer-events: auto;
			margin-bottom: 15px;
			padding: 32px 48px;
			display: flex;
			flex-direction: column;
			gap: 15px 0;
			justify-content: space-around;
			
			.title_box {
				flex-direction: row;
				justify-content: flex-start;
				padding: 18px 20px;
				height: 32px;
				font-size: 18px;
				background-image: linear-gradient(270deg, rgba(0, 230, 224, 0.00) 0%, rgba(0, 230, 224, 0.35) 98%);
				color: #fff;
				//margin: 32px 48px 0;
				border-left: 5px solid #00e6e0;
			}

			.flex_box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 50px;
				//flex: 1;
				//margin: 32px 48px 0;

				.item_box {
					//background: rgba(0, 230, 224, 0.1);
					box-shadow: inset 0px 0px 30px 0px rgba(45, 93, 117, 0.2);
					flex: 1;
					display: flex;
					align-items: center;
					height: 100%;
					justify-content: center;
					position: relative;
					padding: 30px 0;
					color: #fff;
					font-size: 16px;
					cursor: pointer;
					.lt_icon {
						position: absolute;
						left: 0;
						top: 0;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-image: url(../../assets/img/header/bevel_01.png);
						width: 10px;
						height: 10px;
					}

					.rt_icon::after {
						content: '';
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-image: url(../../assets/img/header/bevel_01.png);
						width: 10px;
						height: 10px;
						transform: scaleX(-1);
						position: absolute;
						right: 0;
						top: 0;
					}
				}
				.item_box:hover {
					color: rgba(0, 230, 224, 1);
				}
				.item_box:nth-child(1) {
					background: rgba(0, 105, 255, 0.2);
				}
				.item_box:nth-child(2) {
					background: rgba(0, 230, 224, 0.2);
				}
				.item_box:nth-child(3) {
					background: rgba(0, 153, 230, 0.2);
				}
				.column_box {
					flex-direction: column;
					justify-content: start;
					gap: 20px 0;
					background-image: linear-gradient(0deg, rgba(20, 26, 38, 0.8) 0%, rgba(32, 45, 70, 0.29) 100%) !important;
					padding: 15px 0 !important;

					.grid_list {
						width: 100%;
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						grid-gap: 10px 0;
						.grid_list_item {
							text-align: center;
							.nums {
								margin-top: 5px;
								color: #00E6E0;
							}
						}
					}
				}
				.column_box:hover {
					color: #fff;
				}
			}
		}

		.large_center_higher_bg {
			height: 400px;
			background: linear-gradient(130deg, rgba(36, 134, 185, 0.32) 0%, rgba(32, 45, 70, 0.29) 100%);
			// background: linear-gradient(0deg, rgba(20,26,36,0.8) 0%, rgba(32,45,70,0.29) 100%);
		}
	}

	.showHigherWarp {
		animation: toHigher 0.5s ease;
		animation-fill-mode: forwards;
	}

	.hideHigherWarp {
		animation: toLower 0.5s;
		animation-fill-mode: forwards;
	}

	// 简介拉高显示
	@keyframes toHigher {
		0% {
			height: 220px;
		}

		100% {
			height: 400px;
		}
	}

	// 简介还原
	@keyframes toLower {
		0% {
			height: 400px;
		}

		100% {
			height: 220px;
		}
	}
</style>
