<template>
	<div class="fl_c large_box">
		<celestial-map />
		<div class="large_box_bg">
			<!-- 顶部 -->
			<div class="fl_r al_c large_title" style="z-index: 9999;">
				<div class="flex450 fl_r large_title_left">
					<div class="flex75"></div>
					<div class="flex530 fl_r" style="align-items: center;">
						<div class="large_title_left_active">
							<img src="@/assets/img/header/button_white_01.png" @click="goRouter()" />
						</div>
						<img src="@/assets/img/header/button_normal_02.png" @click="goRouter(`farming`)" />
						<img src="@/assets/img/header/button_normal_03.png" @click="goRouter(`culturalTourism`)" />
					</div>
				</div>
				<div class="fl_r ju_c al_C large_title_center">
					<img class="title" src="@/assets/img/header/title_ssjs.png" />
				</div>
				<div class="flex450 fl_r large_title_right">
					<div class="flex530 fl_r" style="align-items: center;justify-content: flex-end;">
						<img src="@/assets/img/header/button_normal_04.png" @click="goRouter(`invest`)" />
						<img src="@/assets/img/header/button_normal_05.png" @click="goRouter(`partyBuilding`)" />
						<img src="@/assets/img/header/button_normal_06.png" @click="goRouter(`talents`)" />
					</div>
					<div class="flex75"></div>
				</div>
			</div>

			<!-- 下半部分 -->
			<div class="fl_r fl" style="position: relative;margin-bottom: 16px;">
				<!-- 左半边 -->
				<div class="large_left fl_c">
					<div class="width_box">
						<Region></Region>
					</div>
					<div class="width_box">
						<list-distribution />
					</div>
					<div class="width_box">
						<farming-statistics />
					</div>
					<div class="width_box">
						<one-village :propertyList="propertyList" />
					</div>
				</div>
				<!-- 中间 -->
				<div class="large_center fl_c">
					<div  
						class="vr-iframe"
						v-if="showIframe1"
						v-loading="VRLoading"
						element-loading-background="rgba(0, 0, 0, 0.5)"
						element-loading-text="数据加载中"
					>
						<iframe 
							class="ifrmae" 
							v-if="showIframe1" 
							allow="autoplay"
							:src="this.region.vrUrl" 
							@load="VRLoading = false"
							@error="VRLoading = false"
						/>
					</div>
					<div 
						class="video-iframe" 
						v-if="showIframe2" 
						v-loading="VideoLoading"
						element-loading-background="rgba(0, 0, 0, 0.5)"
						element-loading-text="数据加载中"
					>
						<iframe 
							class="ifrmae" 
							v-if="showIframe2" 
							allow="autoplay" 
							@load="VideoLoading = false"
							@error="VideoLoading = false"
							:src="this.region.videoUrl" 
						/>
					</div>
					<!-- 关闭视频 or vr 按钮 -->
					<!-- <el-button class="iconInIframe" icon="el-icon-circle-close " v-if="showIframe2 | showIframe1"
						@click="(showIframe2 = false), (showIframe1 = false)"></el-button> -->
					<div v-if="showIframe2 | showIframe1"
						@click="(showIframe2 = false), (showIframe1 = false)" class="close-btn2">
						<img src="../assets/img/header/closed_02.png" />
					</div>
					<!-- 按钮组 -->
					<div class="group-btns" v-if="!(showIframe2 | showIframe1)">
						<img 
							v-for="(item,index) in arrBtnList" 
							:key="index" 
							:src="currentArrTypeIndex === index ? item.active : item.src"
							@click="handleSelectPoint(item.type,index)"
						/>
					</div>
					<!-- 土地流转资产概览 -->
					<land-circulate-intro></land-circulate-intro>
					<!-- <city-introduction></city-introduction> -->
				</div>
				<!-- 右半边 -->
				<div class="large_right fl_c">
					<div class="width_box">
						<passions-agriculture />
					</div>
					<div class="width_box">
						<agricultural-production />
					</div>
					<div class="width_box">
						<order-closing :orderList="orderList" />
					</div>
				</div>
			</div>
		</div>

		<div class="two-btn fl_r ju_b">
			<div class="btn" @click="showSomeThing(`showVideo`)"></div>
			<div class="btn" @click="showSomeThing(`showVR`)"></div>
		</div>
	</div>
</template>

<script>
	import screenfull from "screenfull";
	import vueSeamlessScroll from "vue-seamless-scroll";
	import CityIntroduction from "@/components/CityIntroduction/CityIntroduction";
	import Region from "@/components/Region/Region";
	import ListDistribution from "@/components/ListDistribution/ListDistribution";
	import FarmingStatistics from "@/components/FarmingStatistics/FarmingStatistics";
	import OneVillage from "@/components/OneVillage/OneVillage";
	import OrderClosing from "@/components/OrderClosing/OrderClosing";
	import SalesPresentation from "@/components/SalesPresentation/SalesPresentation";
	import UnsalableSystem from "@/components/UnsalableSystem/UnsalableSystem";
	import PassionsAgriculture from "@/components/PassionsAgriculture/PassionsAgriculture";
	import AgriculturalProduction from "@/components/AgriculturalProduction/AgriculturalProduction";
	import CelestialMap from "@/components/CelestialMap/CelestialMap";
	import LandCirculateIntro from '@/components/LandCirculateIntro/LandCirculateIntro';
	import {
		goRouter
	} from "@/utils/api/goRouter";
	import {
		getVRList
	} from '@/utils/api/vr';
	import { mapState } from 'vuex';
	export default {
		name: "smartAgriculture",
		components: {
			CityIntroduction,
			vueSeamlessScroll,
			Region,
			ListDistribution,
			FarmingStatistics,
			OneVillage,
			OrderClosing,
			SalesPresentation,
			UnsalableSystem,
			PassionsAgriculture,
			AgriculturalProduction,
			CelestialMap,
			LandCirculateIntro
		},
		computed: {
			...mapState(['region']),
			optionLeft() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: 1, // 开始无缝滚动的数据量
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 2, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		data() {
			return {
				VRLoading: false,
				VideoLoading: false,
				isScreenfull: true,
				propertyList: [],
				orderList: [],
				proportionList: [],
				unsalableData: [],
				unsalableDataName: [],
				scaleList: [],
				scaleStock: [],
				newDate: "",
				newTime: "",
				city: "龙岩",
				newsList: [],
				cropData: [],
				townData: [],
				showIframe1: false,
				showIframe2: false,
				basicInforCont: [{
						icon: "../assets/img/area.png",
						num: 1516,
						unit: "亩",
						name: "耕种总面积",
						color: "#5CB2E7",
					},
					{
						icon: "../assets/img/number.png",
						num: 852,
						unit: "亩",
						name: "种粮作物面积",
						color: "#AABDFF",
					},
					{
						icon: "../assets/img/enterprise.png",
						num: 915,
						unit: "亩",
						name: "经济作物面积",
						color: "#F6C357",
					},
					{
						icon: "../assets/img/members.png",
						num: 15615,
						unit: "万元",
						name: "经济作物产值",
						color: "#3BC99E",
					},
					{
						icon: "../assets/img/production.png",
						num: 4915,
						unit: "个",
						name: "种养农场总数",
						color: "#BEA0FF",
					},
					//  {
					//     icon:'../assets/img/taxProfit.png',
					//     num: 0,
					//     unit: '万元',
					//     name: '税收金额',
					//     color: '#5CB2E7'
					// }
				],
				formalCity: "",
				count_list: {},
				listData: [1, 2, 3, 4, 5, 6, 7],
				classOption: {
					limitMoveNum: 0,
					direction: 1,
					step: 0.2,
				},
				arrBtnList: [
					{ 
						src: require('@/assets/img/icon-all-arr.png'), 
						active:  require('@/assets/img/icon-all-arr-selected.png'),
						type: 'all'
					},
					{ 
						src: require('@/assets/img/icon-village-arr.png'), 
						active:  require('@/assets/img/icon-village-arr-selected.png'),
						type: 'village'
					},
					{ 
						src: require('@/assets/img/icon-farm-arr.png'), 
						active:  require('@/assets/img/icon-farm-arr-selected.png'),
						type: 'farm'
					},
				], // 点位分类按钮组
				currentArrTypeIndex: 0, // 当前类型选中下标
			};
		},
		methods: {
			handleSelectPoint(type,index) {
				this.currentArrTypeIndex = index;
				this.$store.dispatch(`map/setType`, type);
			},
			async getVRList() {
				let res = await getVRList({
					town: this.region.item.town_code
				});
				if(res.data.data.length > 0) {
					let VrUrl = res.data.data[0].src;
					let VideoUrl = res.data.data[0].video;
					this.$store.dispatch(`region/setVrUrl`, VrUrl);
					this.$store.dispatch(`region/setVideo`, VideoUrl);
				}
			},
			showSomeThing(show) {
				if (show == `showVideo`) {
					this.VRLoading = true;
					this.showIframe1 = !this.showIframe1;
				} else {
					this.VideoLoading = true;
					this.showIframe2 = !this.showIframe2;
				}
			},
			goRouter,
			resetCity() {
				this.formalCity = "";
			},
			getFormalCity(e) {
				this.formalCity = e;
				this.unsalableDataName = [];
				this.unsalableData = [];
			},
			async statisticsMessage() {
				// let res = await statisticsMessage();
				// this.cropData = res.data.cropData.reverse();
				this.cropData = [{
						produceName: `2023-03`,
						produceQuantity: `4778`,
					},
					{
						produceName: `2023-02`,
						produceQuantity: `2867`,
					},
					{
						produceName: `2023-01`,
						produceQuantity: `3823`,
					},
					{
						produceName: `2022-12`,
						produceQuantity: `1912`,
					},
					{
						produceName: `2022-11`,
						produceQuantity: `956`,
					},
					{
						produceName: `2022-10`,
						produceQuantity: `2342`,
					},
					{
						produceName: `2022-9`,
						produceQuantity: `1503`,
					},
				];
				// this.townData = res.data.townData;
			},

			// async homeStatistics() {
			//     let res = await homeStatistics({
			//         type: '02',
			//         area_code: '龙岩市',
			//         statistical_type: '01'
			//     })
			//
			//     res.data.unsalableData[0].map(item => {
			//         item.date = this.$moment(item.date).format('YYYY-MM-DD')
			//     })
			//     res.data.unsalableData.map(item => {
			//         this.unsalableDataName.push(item[0].produceName)
			//     })
			//     this.unsalableData = res.data.unsalableData
			//     this.newsList.push({title: res.data.topString})
			// },
			requestFullScreen() {
				this.isScreenfull = screenfull.isFullscreen;
				if (screenfull.isEnabled) {
					screenfull.toggle();
				}
			},
		},
		created() {
			this.getVRList();
		},
		mounted() {},
	};
</script>

<style scoped lang="scss">
	.large_box {
		width: 100%;
		height: 100%;
	}

	.large_box_bg {
		// background-image: radial-gradient(circle at 50% 50%, rgba(2, 14, 25, 0.2) 35%, rgba(2, 9, 32, 0.85) 50%);
		//background-image: radial-gradient(55% 97% at 50% 48%, rgba(2, 14, 25, 0.2) 44%, rgba(2, 9, 32, 0.85) 52%);
		background-image: radial-gradient(65% 130% at 51% 45%, rgba(2, 14, 25, 0.1) 10%, rgba(2, 9, 32, 0.93) 55%);
		z-index: 333;
		flex: 1;
		display: flex;
		flex-direction: column;
		pointer-events: none;
		// 去除全屏时，显示的滚动条
		overflow: hidden;
	}

	.large_title {
		height: 90px;
		color: #fff;
		font-size: 20px;
		letter-spacing: 2px;
		filter: drop-shadow(10px 10px 10px rgba(2, 9, 32, 0.93));
		pointer-events: auto;
		cursor: pointer;
	}

	.large_title_center {
		background-image: url(./../assets/img/header/top_02.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 600px;
		height: 90px;
		.title {
			width: 100%;
			height: 100%;
		}
	}

	.large_title_left {
		background-image: url(./../assets/img/header/top_01.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 670px;
		height: 72px;

		.large_title_left_active {
			background-image: url(./../assets/img/header/button_light.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 148px;
			height: 54px;
		}

		img {
			width: 148px;
			height: 54px;
		}
	}

	.large_title_right {
		position: relative;
		width: 670px;
		height: 72px;

		img {
			width: 148px;
			height: 54px;
			z-index: 333;
		}
	}

	.large_title_right::after {
		content: '';
		background-image: url(./../assets/img/header/top_01.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 670px;
		height: 72px;
		transform: scaleX(-1);
		position: absolute;
	}

	.large_line {
		width: 500px;
		height: 40px;
	}

	.line {
		width: 2px;
		height: 40px;
		margin: 0 30px;
		background-color: #dddddd;
	}

	.full_img {
		width: 40px;
		height: 40px;
	}

	.full_screen {
		top: 35px;
		right: 10px;
		z-index: 99999;
	}

	.scorll_box {
		width: 430px;
		height: 50px;
		line-height: 50px;
		color: #fff;
		overflow: hidden;
		font-size: 16px;
	}

	.large_left {
		// width: 490px;
		width: 400px;
		height: 100%;
		//background: rgba(255, 255, 255, 0.1);
		margin-left: 43px;
		pointer-events: auto;
		.width_box {
			margin-bottom: 5px !important;
		}
		.width_box:nth-child(3) {
			margin-bottom: 10px !important;
		}
	}

	.width_box {
		// width: 490px;
		// height: 314px;
		margin-bottom: 15px;
	}

	.width_box:nth-of-type(3n) {
		//margin-bottom: 0px;
	}

	.large_center {
		// width: 895px;
		height: 100%;
		margin: 0px 20px;
		width: 988px;
		// justify-content: flex-end;
		justify-content: space-between;

		position: relative;
		.large_center_bg {
			width: 100%;
			height: 220px;
			background-image: url(./../assets/img/pop_down.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			//position: absolute;
			// bottom: 0;
			// right: 500px;
			// left: 500px;
			margin: 0 auto;
			overflow: hidden;
			box-sizing: border-box;
			pointer-events: auto;
			margin-bottom: 15px;
		}
		
		.group-btns {
			gap: 0 10px;
			display: flex;
			align-items: center;
			pointer-events: auto;
			justify-content: flex-end;
			img {
				cursor: pointer;
				pointer-events: auto;
				width: 7%;
			}
		}
	}

	.width_center {
		width: 100%;
		height: 314px;
	}

	.large_right {
		width: 400px;
		height: 100%;
		margin-right: 43px;
		pointer-events: auto;
	}

	.left_bag {
		// z-index: 0.5;
		position: fixed;
		width: 495px;
		height: calc(100% - 110px);
		// opacity: 0.5;
		overflow-y: hidden;
		margin-top: 2px;
	}

	.right_bag {
		z-index: 0.5;
		// opacity: 0.5;
		margin-top: 2px;
		position: fixed;
		right: 0;
		width: 490px;
		height: calc(100% - 110px);
		-moz-transform: scaleX(-1);

		-o-transform: scaleX(-1);

		overflow-y: hidden;
	}

	.left_bag1 {
		z-index: 0.5;
		// opacity: 0.5;
		position: fixed;
		width: 935px;
		height: 308px;
		bottom: 0;
		left: 495px;
	}

	.left_bag2 {
		z-index: 0.5;
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 110px;
		top: 0;
		-moz-transform: scaleY(-1);
		-webkit-transform: scaleY(-1);
		-o-transform: scaleY(-1);
		transform: scaleY(-1);
	}

	.basicInfo {
		width: 47.5%;
		height: 100px;
		position: absolute;
		top: 120px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9999;
		background: linear-gradient(268.32deg,
				rgba(14, 44, 92, 0.62) -4.33%,
				#0e2c5c 47.68%,
				rgba(14, 44, 92, 0.35) 110.26%);
		border: 1.5px solid;
		border-image: linear-gradient(180deg,
				rgba(0, 169, 255, 0.5) 0%,
				rgba(0, 157, 237, 0.231) 93.75%) 20 20;
		font-style: normal;
		font-weight: normal;
		text-align: center;
		box-sizing: border-box;
	}

	.basicInfo img {
		height: 50px;
		width: 50px;
		border-radius: 0px;
	}

	.basicInfo .num {
		font-family: PingFang SC;
		font-size: 24px;
		line-height: 24px;
		color: #5cb2e7;
	}

	.basicInfo .val {
		font-family: Microsoft JhengHei UI;
		font-size: 24px;
		line-height: 24px;
		color: #dddddd;
		margin-top: 20px;
	}

	.basicInfo .num span {
		font-size: 20px !important;
		line-height: 24px;
		color: #dddddd;
	}

	.basicInfo .basicInfo-Item .infoName {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #969696;
		margin-top: -1rem;
	}

	.flex {
		display: flex;
	}

	.flex-between {
		justify-content: space-between;
		align-items: center;
	}

	.nowrap {
		flex-flow: nowrap;
	}

	.mar_top {
		font-family: "mao";
		font-style: normal;
		font-weight: 700;
		font-size: 55px;
		// line-height: 56px;
		/* identical to box height */
		letter-spacing: 1em;
		background: linear-gradient(184.76deg, #faffff 19.17%, #00ffe0 78.52%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		margin-top: 0 !important;
		padding-left: 55px;
	}

	.zindex1 {
		z-index: 1;
		color: #ffffff;
	}

	.title-box {
		flex-direction: row;
		justify-content: flex-start;
		padding: 18px 20px;
		height: 32px;
		font-size: 18px;
		background-image: linear-gradient(270deg, rgba(0, 230, 224, 0.00) 0%, rgba(0, 230, 224, 0.35) 98%);
		color: #fff;
		margin: 32px 48px 0;
		border-left: 5px solid #00e6e0;
	}

	.scroll-box {
		height: 210px;
		padding: 40px 20px 0;
		overflow: hidden;

		// background: red;
		ul {
			list-style: none;
			padding: 0;
			margin: 0 auto;

			li,
			a {
				display: block;
				height: 30px;
				line-height: 30px;
				display: flex;
				justify-content: space-between;
				font-size: 15px;
			}
		}
	}

	.flex450 {
		flex: 450;
	}

	.flex750 {
		flex: 725;
	}

	.scroll {
		font-size: 20px;
		line-height: 50px;
	}

	.flex75 {
		flex: 100;
		height: 100%;
	}

	.flex530 {
		flex: 530;
	}

	.font23 {
		// padding-top: 10px;
		font-size: 25px;
		font-weight: bold;
	}

	.warp {
		width: 854px;
		height: 110px;
		margin: 20px auto;
		padding-top: 20px;
		overflow: hidden;
		font-size: 14px;
		font-weight: 400;
		line-height: 30px;
		color: #fff;
		font-size: 14px;
	}

	.two-btn {
		width: 42%;
		position: absolute;
		top: 20px;
		height: 50px;
		// background-color: red;
		left: 29%;
		margin: 0 auto;
	}

	.btn {
		width: 10%;
		height: 100%;
		// background-color: #00ffe0;
		z-index: 999999999;
	}

	.vr-iframe,.video-iframe {
		flex: 1;
		width: 100%;
		z-index: 333;
		margin-bottom: 15px;
		border: none;
		pointer-events: auto;
		.ifrmae {
			// width: 51.3%;
			// position: absolute;
			// top: 110px;
			// height: 720px;
			// // background-color: red;
			// left: 24.2%;
			// margin: 0 auto;
			flex: 1;
			width: 100%;
			z-index: 333;
			margin-bottom: 15px;
			//position: relative;
			border: none;
			pointer-events: auto;
			height: 100%;
		}
	}
	
	.iconInIframe {
		position: absolute;
		top: 0;
		//right: 0;
		right: 22%;
		left: unset !important;
		pointer-events: auto;
	}

	.fontMao {
		font-family: "mao";
	}
	
	/deep/ .close-btn2 {
		pointer-events: auto;
	}
</style>
